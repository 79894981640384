import { useEffect, useState } from "react";
// import user from "../../../../assests/Ellipse 1490.png";
import file from "../../../../assests/filesend.svg";
import send from "../../../../assests/send.svg";
import ffile from "../../../../assests/ffile.png";
import del from "../../../../assests/del.png";
import friend from "../../../../assests/Ellipse 1490 (1).png";
import { BsThreeDotsVertical } from "react-icons/bs";
// import { useLocation } from 'react-router-dom'
import { db } from "../../../../confiq/firebase";
import { collection, query, getDoc, setDoc, addDoc, updateDoc, orderBy, doc, onSnapshot, Timestamp } from "firebase/firestore";
import { useLocation, useParams } from "react-router-dom";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import moment from "moment";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { SaveTicket } from "../../../../lib/Api/CustomerSupport";
const UserMessages = ({ type, getDetail, convId, setTicketDetail, ticketDetail, currentUser, title, users, setUpdateMessage }) => {
  const [Messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [UserMessage, setMessage] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [conversationInfo, setConversationInfo] = useState({});
  const user = useSelector((state) => state?.userAuth?.userInfo);

  let { id } = useParams();
  const location = useLocation().pathname

  let checkLoc = location === `/dinss/tech/registerUser-detail/${id}`
  // Create a new ticket and send initial messages
  let createTicket = async (UserMessage) => {
    let payload = {
      "subject": UserMessage,
      "message": UserMessage,
      "ticket_user_id": Number(id)
    };

    let { res } = await SaveTicket(payload);
    if (res) {
      let obj = Object.assign({}, ...res)
      getDetail()
      sender(obj?.ticket);
      receiver(obj?.ticket);
      fetchMessages(obj?.ticket)
      setUpdateMessage('');
    }
  };

  // Fetch messages for the conversation
  const fetchMessages = async (list) => {
    try {
      setLoading(true);
      const q = query(
        collection(db, `Users/${0}/Conversation/${list?.id || ticketDetail?.id}/Messages`),
        orderBy('messageTime', 'desc')
      );

      const newUnsubscribe = onSnapshot(q, (querySnapshot) => {
        const newMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        newMessages.sort((a, b) => new Date(a.messageTime) - new Date(b.messageTime));
        const groupedMessages = {};
        newMessages.forEach((message) => {
          const date = new Date(message.messageTime).toLocaleDateString();
          if (!groupedMessages[date]) {
            groupedMessages[date] = [];
          }
          groupedMessages[date].push(message);
        });
        setMessages(groupedMessages);
        setLoading(false);
      });

      setUnsubscribe(() => newUnsubscribe);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };

  const utcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
  const nextDay = moment.utc().add(1, 'days').format('YYYY-MM-DD HH:mm:ss');


  // const ensureUserExists = async (userId) => {
  //   const userRef = doc(db, `Users/${userId}`);
  //   const userSnapshot = await getDoc(userRef);
  //   if (!userSnapshot.exists()) {
  //     // Create the user if it doesn't exist
  //     await setDoc(userRef, {
  //       isOnline: true,
  //       phone: "000", // Placeholder, adjust as needed
  //       userId: userId,
  //       userImage: userId === '0' ? "https://api.primecarapp.com/email_assets/icon.profile.png" : convId?.img, // Placeholder image URL
  //       userName: userId === '0' ? "Assistance Primecar" : convId?.name, // Example naming
  //     });
  //   }
  // };


  // Function to handle message sending for sender
  let sender = async (list) => {
    try {

      const userRef = doc(db, `Users/0`);
      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        // Create the user if it doesn't exist
        await setDoc(userRef, {
          isOnline: true,
          phone: "000", // Placeholder, adjust as needed
          userId: id,
          userImage: "https://api.primecarapp.com/email_assets/icon.png", // Placeholder image URL
          userName: "Assistance Primecar", // Example naming
        });
      }
      const senderConversationRef = doc(db, `Users/${0}/Conversation/${list?.id || ticketDetail?.id}`);
      const senderConversationSnapshot = await getDoc(senderConversationRef);
      const startDate = Timestamp.now();
      const endDate = Timestamp.fromDate(new Date(startDate.toDate().getTime() + 24 * 60 * 60 * 1000));

      if (!senderConversationSnapshot.exists()) {
        const conversationData = {
          conversationId: `${list?.id || ticketDetail?.id}`,
          conversationType: "single", // Adjust as necessary
          lastMessage: UserMessage,
          lastMessageId: "", // This will be updated after the message is sent
          lastMessageTime: utcTime,
          ownerId: id,
          otherUserId: `${id}`,
          renterId: "0", // Adjust as per your logic
          startDate: startDate,
          endDate: endDate, // Define this based on your use case
        };

        // Check if the location matches and add `isForHelp: true` if it does
        if (checkLoc) {
          conversationData.isForHelp = true;
        }

        await setDoc(senderConversationRef, conversationData);
        // await setDoc(senderConversationRef, {
        //   conversationId: `${list?.id || ticketDetail?.id}`,
        //   conversationType: "single", // Adjust as necessary
        //   lastMessage: UserMessage,
        //   lastMessageId: "", // This will be updated after the message is sent
        //   lastMessageTime: utcTime,
        //   ownerId: id,
        //   otherUserId: `${id}`,
        //   renterId: "0", // Adjust as per your logic
        //   startDate: startDate,
        //   endDate: endDate, // Define this based on your use case
        // });
      }

      const q = collection(
        db,
        `Users/${0}/Conversation/${list?.id || ticketDetail?.id}/Messages`
      );

      let messageInfo = {
        conversationId: `${list?.id || ticketDetail?.id}`,
        senderId: '0',
        messageText: UserMessage,
        messageImage: "",
        messageId: "",
        isRead: false,
        messageTime: utcTime,
        messageType: "text",
      }
      let res = await addDoc(q, messageInfo);
      if (res) {
        setMessage('');
      }

      const updateMessage = doc(
        db,
        `Users/${0}/Conversation/${list?.id || ticketDetail?.id}/Messages/${res?.id}`
      );

      await updateDoc(updateMessage, {
        messageId: res?.id
      });

      const conversationDocRef = doc(
        db,
        `Users/${0}/Conversation/${list?.id || ticketDetail?.id}`,
      );
      try {
        let updatConv = {
          lastMessage: UserMessage,
          lastMessageTime: utcTime,
          lastMessageId: res?.id,
        }
        if (checkLoc) {
          updatConv.isForHelp = true;
        }
        await updateDoc(conversationDocRef, updatConv);
        console.log('Last message in conversation updated successfully!');
      } catch (error) {
        console.error('Error updating last message in conversation:', error);
      }

    } catch (error) {
      console.error('Error sending message from sender:', error);
    }
  };

  // Function to handle message sending for receiver
  // Function to handle message sending for receiver
  let receiver = async (list) => {
    try {
      // Ensure the receiver exists before proceeding
      const userRef = doc(db, `Users/${id}`);
      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        // Create the user if it doesn't exist
        await setDoc(userRef, {
          isOnline: true,
          phone: "000", // Placeholder, adjust as needed
          userId: id,
          userImage: convId?.img || ticketDetail?.user?.image, // Placeholder image URL
          userName: convId?.name || ticketDetail?.user?.first_name, // Example naming
        });
      }

      const receiverConversationRef = doc(db, `Users/${id}/Conversation/${list?.id || ticketDetail?.id}`);
      const receiverConversationSnapshot = await getDoc(receiverConversationRef);
      const startDate = Timestamp.now();
      const endDate = Timestamp.fromDate(new Date(startDate.toDate().getTime() + 24 * 60 * 60 * 1000));

      if (!receiverConversationSnapshot.exists()) {

        const conversationData = {
          conversationId: `${list?.id || ticketDetail?.id}`,
          conversationType: "single", // Adjust as necessary
          lastMessage: UserMessage,
          lastMessageId: "", // This will be updated after the message is sent
          lastMessageTime: utcTime,
          ownerId: id,
          isForHelp: true,
          otherUserId: "0",
          renterId: "0", // Adjust as per your logic
          startDate: startDate,
          endDate: endDate, // Define this based on your use case
        };

        // Check if the location matches and add `isForHelp: true` if it does
        if (checkLoc) {
          conversationData.isForHelp = true;
        }

        await setDoc(receiverConversationRef, conversationData);
        // await setDoc(receiverConversationRef, {
        // conversationId: `${list?.id || ticketDetail?.id}`,
        // conversationType: "single", // Adjust as necessary
        // lastMessage: UserMessage,
        // lastMessageId: "", // This will be updated after the message is sent
        // lastMessageTime: utcTime,
        // ownerId: id,
        // isForHelp: true,
        // otherUserId: "0",
        // renterId: "0", // Adjust as per your logic
        // startDate: startDate,
        // endDate: endDate, // Define this based on your use case
        // });
      }

      const q = collection(
        db,
        `Users/${id}/Conversation/${list?.id || ticketDetail?.id}/Messages`
      );

      let messageInfo = {
        conversationId: `${list?.id || ticketDetail?.id}`,
        senderId: '0',
        messageText: UserMessage,
        messageImage: "",
        messageId: "",
        isRead: false,
        messageTime: utcTime,
        messageType: "text",
      }
      let res = await addDoc(q, messageInfo);

      const messageUpdate = doc(
        db,
        `Users/${id}/Conversation/${list?.id || ticketDetail?.id}/Messages/${res?.id}`
      );

      await updateDoc(messageUpdate, {
        messageId: res?.id
      });

      const conversationDocRef = doc(
        db,
        `Users/${id}/Conversation/${list?.id || ticketDetail?.id}`,
      );
      try {
        let updatConv = {
          lastMessage: UserMessage,
          lastMessageTime: utcTime,
          lastMessageId: res?.id,
        }
        if (checkLoc) {
          updatConv.isForHelp = true;
        }
        await updateDoc(conversationDocRef, updatConv);
        console.log('Last message in conversation updated successfully!');
      } catch (error) {
        console.error('Error updating last message in conversation:', error);
      }

    } catch (error) {
      console.error('Error sending message from receiver:', error);
    }
  };


  // Handle message sending with conversation creation if needed
  const sendMessage = async () => {
    if (Messages?.length === 0) {
      await createTicket(UserMessage);
    } else {
      await sender();
      await receiver();
      setUpdateMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [Messages]);

  useEffect(() => {
    if (convId) {
      setConversationInfo(convId);
    }
  }, [convId]);

  useEffect(() => {
    if (Object.keys(ticketDetail)?.length > 0) {
      fetchMessages();
    } else {
      setMessages([]);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [convId]);

  const userInfo = convId?.userInfo === "ticketDetail";

  // console.log("con" , convId )
  // console.log("detail" , ticketDetail )
  // console.log("message" , Messages )


  return (
    <>
      <div className="flex flex-col flex-auto h-full ">
        <div
          className={`flex flex-col flex-auto flex-shrink-0 relative   ${type === "ticket"
            ? "bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md"
            : "bg-gray-100 rounded-md"
            } h-full relative p-4`}
        >
          {!loading && userInfo && Object.keys(Messages).length > 0 || users?.length > 0 ? (
            <>
              <div className='flex justify-between items-center pb-4'>
                <div className='flex items-center'>
                  <div className="h-[60px] w-[60px] rounded-full overflow-hidden">
                    <img
                      src={ticketDetail?.user?.image}
                      alt="user"
                      className="object-cover  h-[60px] w-[60px]"
                    />
                  </div>
                  <div className='px-3'>
                    <h2 className="lg:text-[26px] lg:leading-[39px] text-[18px] font-semibold text-[#444444]">
                      {ticketDetail?.user?.first_name}
                    </h2>
                    {convId?.isOnline && (
                      <p className="text-[12px] text-[#898989] flex items-center leading-[18px]">
                        <p className="w-[10px] mr-2 h-[10px] bg-[#06A551] rounded-full border-0" />
                        Online
                      </p>
                    )}
                  </div>
                </div>
                <div className='bg-white rounded-md relative flex z-10 items-center justify-center h-[40px] w-[36px]'>
                  <div class="group inline-block  ">
                    <button
                      class="outline-none focus:outline-none  px-3 py-1  flex items-center"
                    >
                      <BsThreeDotsVertical className='text-[20px] text-[#898989]' />

                    </button>
                    <ul
                      class={`bg-white shadow-md border broder-gray-100  rounded-md transform scale-0 group-hover:scale-100 absolute 
                    transition duration-150 ease-in-out top-full w-[150px] -right-12  `}
                    >
                      <li class="rounded-sm cursor-pointer actions flex items-center px-3 py-3 hover:bg-gray-100">
                        <img src={ffile} alt="file_img" className='object-cover' />
                        <h2 className='ml-2 text-[14px]' >View details</h2>
                      </li>

                      <li class="rounded-sm cursor-pointer flex items-center px-3 py-3 hover:bg-gray-100">
                        <img src={del} alt="file_img" className='object-cover' />
                        <h2 className='ml-2 text-[14px]'>Delete</h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {type === "ticket" ? "" :
                <p className='border border-gray-200' />}
              <div className={`max-h-[600px] overflow-y-auto  user_list`} ref={ref}>
                {Object.keys(Messages).map((date) => (
                  <>
                    <div className={`${type === "ticket" ? 'pt-0' : 'pt-5'}  flex justify-between items-center`}>
                      <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                      <p className='text-[12px] text-[#444444] w-[50%] text-center'>{date}</p>
                      <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                    </div>
                    {Messages[date].map((message) => {
                      return (
                        <div className="flex flex-col  overflow-x-auto  ">
                          <div className="flex flex-col ">
                            <div className="grid grid-cols-12  ">
                              {message?.senderId !== "0" ?
                                <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg"} >
                                  <div className="flex flex-row ">
                                    <div
                                      className=""
                                    >
                                      <img src={ticketDetail?.user?.image} alt="user" className={`object-cover rounded-full ${message?.messageText?.length > 100 ? 'lg:w-[120px] border lg:h-auto' : 'lg:w-[40px] lg:h-[40px]'
                                        } md:w-[40%] w-[70%]`} />
                                    </div>
                                    <div className=''>
                                      <div
                                        className="relative ml-3 text-sm bg-white py-2 px-4 lg:w-auto w-full shadow rounded-xl"
                                      >
                                        <div className='text-[#444444] text-[14px]'>{message?.messageText}</div>
                                      </div>
                                      <div className='flex justify-between items-center pt-2 pl-4'>
                                        <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.messageTime).format(
                                          "LT"
                                        )}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                  <div className="flex  justify-start flex-row-reverse">
                                    <div
                                      className=""
                                    >
                                      <img src={"https://api.primecarapp.com/email_assets/icon.png"} alt="user" className={`object-cover rounded-full ${message?.messageText?.length > 100 ? 'lg:w-[240px] border lg:h-auto' : 'lg:w-[40px] lg:h-[40px]'
                                        } md:w-[40%] w-[70%]`} />
                                    </div>
                                    <div className=' mr-3'>
                                      <div
                                        className="relative text-sm bg-white py-2 px-4 shadow rounded-xl"
                                      >
                                        <div className='text-[#444444] text-[14px]' style={{ whiteSpace:'pre-line' }}> {message?.messageText}</div>
                                      </div>
                                      <div className='flex justify-between items-center pt-2 pl-4'>
                                        <p className='text-[#9F9F9F] text-[12px] '> {moment(message?.messageTime).format(
                                          "LT"
                                        )}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }

                            </div>
                          </div>
                        </div >
                      )
                    })}
                  </>
                ))}
              </div>

            </>
          ) : loading ? <div className="flex items-center justify-center pt-40"><LoaderSpinner type="chatLoad" color={"#FF8C00"} /></div> : <div className="text-center font-bold pt-20">No Conversation</div>}

          <div className='flex items-center absolute bottom-2 w-[96%]'>
            <div className="mr-4">
              <img src={file} alt="send" className='object-cover' />
            </div>
            <form onSubmit={handleSubmit} className="w-full">
              <div
                className={`flex flex-row items-center h-[50px] rounded-xl ${type === "ticket" ? "bg-[#F5F5F5]" : "bg-white"
                  } w-full px-2`}
              >
                <div className="flex-grow ml-2">
                  <div className="relative w-full mt-3">
                    <textarea
                      name="UserMessage"
                      placeholder="Type a message"
                      value={UserMessage}
                      onChange={(e) => setMessage(e.target.value)}
                      className="flex w-full bg-transparent focus:outline-none focus:border-indigo-300 h-full "
                    />

                  </div>
                </div>
                <div className="ml-4" onClick={(e) => handleSubmit(e)}>
                  <img src={send} alt="send" className="object-cover cursor-pointer" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >

    </>
  );
};

export default UserMessages;