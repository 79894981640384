import React from 'react'
import Messages from '../../manageUser/chats/Messages'
// import { BsCheck } from 'react-icons/bs'
import { MdKeyboardArrowRight } from 'react-icons/md'
// import user from '../../../../assests/ticketuser.png'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { db } from "../../../../confiq/firebase";

import {
    collection,
    query,
    getDocs,
} from "firebase/firestore";
import { AssignTicket, GetUserDetails, GetUserTicketDetail, MarkAsResolve, SaveTicket } from '../../../../lib/Api/CustomerSupport'
import LoaderSpinner from '../../../../util/LoaderSpinner'
import moment from 'moment'
import { GetRoles } from '../../../../lib/Api/AdminApi'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserMessages from './UserMessages'
// import CommonDropDown from "../dropDown";

const schema = yup.object({
    role: yup.string().required("Role is required"),
    dueDate: yup.string().required("Due Date is required"),
});
const RegisterUserDetail = () => {
    const [unRead, setUnRead] = useState()
    const [currentUser, setCurrentUser] = useState({});
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [role, setRole] = useState([]);
    const [loading, setLoading] = useState(false);
    const [markLoading, setMarkLoading] = useState(false);
    const [loadingRole, setLoadingRole] = useState(false);
    const [assignLoading, setAssignLoading] = useState(false);
    const [ticketDetail, setTicketDetail] = useState({});
    const [userDetail, setUserDetail] = useState({});
    const [UpdateMessage, setUpdateMessage] = useState({});

    const { id } = useParams()

    let checkType = "Chat Support"

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

    let getTicketDetail = async () => {
        let res = await GetUserTicketDetail(id)
        if (res !== null) {
            setTicketDetail(res)
        }
    }




    const getDetail = async () => {
        setLoading(true)
        let { res } = await GetUserDetails(id)
        if (res) {
            setLoading(false)
            getTicketDetail()
            setCurrentUser(res)
        }
    }

    const onSubmit = async (data) => {
        try {
            let dateFormate = moment(data.dueDate).format('DD/MM/YYYY')
            let paylaod = {
                "assignee_id": data.role,
                "due_date": dateFormate,
                "ticket_id": ticketDetail?.id
            }
            setAssignLoading(true)
            let { res } = await AssignTicket(paylaod)
            setAssignLoading(false)
        }
        catch (err) { }
    }

    const handleMarkAs = async () => {
        setMarkLoading(true)
        let { res } = await MarkAsResolve(id)
        setMarkLoading(false)
        getDetail()

    }

    const AdminRoles = async () => {
        let { res } = await GetRoles();
        let rols = Object.assign({}, ...res);
        if (rols) {
            setRole(rols.roles)
        }
    }



    useEffect(() => {
        let fetch = async () => {
            const q = query(collection(db, `Users/`));
            const querySnapshots = await getDocs(q);
            const Users = querySnapshots.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllUsers(Users);
        };
        fetch();
        getDetail()
        AdminRoles()
    }, []);


    // useEffect(() => {
    //     const fetch = async () => {
    //         try {
    //             const db = getFirestore(Firebaseapp);
    //             const Conv = query(collection(db, `Users/${id}/Conversation`));
    //             const querySnapshot = await getDocs(Conv);
    //             const UsersConv = querySnapshot.docs.reduce((acc, doc) => {
    //                 const data = doc.data();
    //                 acc[data.renterId] = { id: doc.id, ...data };
    //                 return acc;
    //             }, {});

    //             const arr = allUsers?.map(user => {
    //                 const userConv = UsersConv[user.id];
    //                 if (userConv) {
    //                     const combinedObj = { ...user, ...userConv };
    //                     if (combinedObj.id === id) {
    //                         setCurrentUser(combinedObj);
    //                     }
    //                     return combinedObj;
    //                 }
    //                 return null;
    //             }).filter(Boolean);

    //             setUsers(arr);
    //         } catch (error) {
    //             console.error("Error fetching data: ", error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     if (allUsers?.length > 0) {
    //         fetch();
    //     }
    // }, [allUsers, id, ticketDetail]);

    // console.log("current", currentUser)
    let convId = {
        ownerId: 0,
        conversationId: currentUser?.id,
        otherUserId: currentUser?.otherUserId,
        renterId: currentUser?.otherUserId,
        img: currentUser?.userImage,
        name: currentUser?.userName,
        userInfo: "ticketDetail"
    }

    let check = ticketDetail.status === 0 

    let checkUser = allUsers.find((f) => f.userId === "0")

    return (
        <>
            {loading ? <LoaderSpinner type="tableLoad" /> :
                <>

                    <div className='flex items-center'>
                        <h1 className='text-[#ACACAC] text-[16px]'>
                            <Link to={`/dinss/tech/chatWithUsers`}>
                                {checkType}
                            </Link>
                        </h1>
                        <MdKeyboardArrowRight className='text-[#ACACAC] mx-2 text-[20px]' />
                        <h1 className='text-[#FF8C00] text-[16px]'> {
                            `ChatID #${ticketDetail?.id || "N/A"}`} </h1>

                    </div>

                    <div className='bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] rounded-md mt-10 mb-5 p-5'>
                        <div className='flex items-center'>
                            <p className='text-[#98A2B3] text-[14px] '>
                                {checkType}
                            </p>
                            <p className='text-[#444444] text-[14px] pl-2'>#{ticketDetail?.id || "N/A"}</p>
                        </div>

                    </div>
                    <div className="grid lg:grid-cols-4 gap-4">
                        <div className='lg:col-span-3'>
                            <UserMessages getDetail={getTicketDetail} ticketDetail={ticketDetail} type="ticket" title={"Support"} currentUser={checkUser} setTicketDetail={setTicketDetail} setUpdateMessage={setUpdateMessage} convId={convId} setUnRead={setUnRead} />
                        </div>
                        <div className='w-full shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] px-5 py-5 bg-white rounded '>
                            <button disabled={check ? false : true} onClick={() => handleMarkAs()} className='h-[40px] text-[14px] w-full bg-[#2BA676] flex justify-center items-center font-semibold text-white'>
                                {!markLoading && check &&
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path d="M6.00016 11.1698L1.83016 6.99984L0.410156 8.40984L6.00016 13.9998L18.0002 1.99984L16.5902 0.589844L6.00016 11.1698Z" fill="white" />
                                    </svg>
                                }
                                {
                                    markLoading ? <LoaderSpinner /> : check ? "Mark as Resolved" : "Resolved"
                                }

                            </button>
                            <div className="pt-5 border-b border-[#ACACAC]">
                                <h1 className='text-[16px] font-semibold'>Ticket details</h1>
                                <ul className='pt-5'>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Ticket ID</p>
                                        <p className='text-[16px] text-[#898989]'>#{ticketDetail?.id || "N/A"}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Status</p>
                                        <p className='text-[12px] text-[#0041B2] px-1.5 font-medium py-[2px] bg-[#E9F3FF] rounded-full'>{check ? "Open" : "Close"}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Req Date</p>
                                        <p className='text-[16px] text-[#898989]'>{moment(ticketDetail?.updated_at).format('DD/MM/YYYY')}</p>
                                    </li>
                                    <li className='flex justify-between pb-4'>
                                        <p className='text-[#ACACAC] text-[14px]'>Type</p>
                                        <p className='text-[16px] text-[#898989]'>General</p>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="pt-5 ">
                                    <h1 className='text-[16px] font-semibold'>User detail</h1>
                                    <ul className='pt-5'>
                                        <li className='flex items-center  pb-4'>
                                            <img src={currentUser?.image} alt="user" className='object-cover w-[36px] h-[36px] rounded-full' />
                                            <p className='text-[14px] text-[#444444] px-2'>{currentUser?.first_name}   {currentUser?.last_name}</p>
                                        </li>
                                        <li className='pb-4'>
                                            <p className='text-[#98A2B3] text-[14px] '>Email</p>
                                            <p className='text-[14px] pt-2 text-[#898989]'>{currentUser?.email}</p>
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#98A2B3] text-[14px]'>Phone</p>
                                            <p className='text-[14px] text-[#898989] pt-2'>{currentUser?.phone_without_code} {currentUser?.phone_number}</p>
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#606060] text-[14px] py-2'>Assign to</p>
                                            <select {...register('role')} className="border rounded-md text-[#ACACAC] h-[40px] outline-none px-2 w-full">
                                                <option>{loadingRole ? 'loading...' : "Select Role"}</option>
                                                {
                                                    role.map((r, i) => (
                                                        <option key={i} value={r?.id}>{r?.role}</option>
                                                    ))
                                                }
                                            </select>
                                            {/* <CommonDropDown {...register("role")} list={role} /> */}
                                            {errors.role && (
                                                <p className="text-red-500 text-sm text-start pt-1 ">
                                                    {errors.role.message}
                                                </p>
                                            )}
                                        </li>
                                        <li className=' pb-5'>
                                            <p className='text-[#606060] text-[14px]'>Due date</p>
                                            <input type="date"
                                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                                {...register('dueDate')
                                                } className='w-full ticket text-[#ACACAC] text-[14px] mt-2 border border-[#E0E0E0] h-[38px] rounded-md focus:outline-none px-2' />
                                            {errors.dueDate && (
                                                <p className="text-red-500 text-sm text-start pt-1 ">
                                                    {errors.dueDate.message}
                                                </p>
                                            )}
                                        </li>
                                    </ul>
                                </div>

                                <div className='pt-8'>
                                    <button disabled={Object.keys(ticketDetail)?.length === 0 ? true : false} className='h-[40px] disabled:bg-opacity-[0.5] text-[14px] w-full bg-[#FF8C00] flex justify-center items-center font-semibold text-white rounded-md'>
                                        {
                                            assignLoading ? <LoaderSpinner /> :
                                                "Assign ticket"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default RegisterUserDetail