import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"


// Get Customer Suport list 
export const GetCustomerSupport = async () => {
    try {
        let response = await callPrivateApi('admin/support-ticket/list?page=1&per_page=10', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Unread
export const GetUnreadMessage = async () => {
    try {
        let response = await callPrivateApi('admin/support-ticket/unread-count', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return obj?.unread_messages
        }
    } catch (err) { }
}
// Get Customer Suport list 
export const GetChatSupport = async () => {
    try {
        let response = await callPrivateApi('admin/support-ticket/list?type=support_chat?page=1&per_page=10', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}





// Get Customer Suport list BY FILTER 
export const GetSupportByPage = async (page_no) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/list?page=${page_no}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Customer Suport list BY FILTER 
export const GetChatSupportByPage = async (page_no) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/list?type=support_chat?page=${page_no}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Customer Suport list BY FILTER 
export const GetSupportSearch = async (page_no, search) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/list?search=${search}&page=${page_no}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Customer Suport list BY FILTER 
export const MarkAsResolve = async (id) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/resolve/${id}`, 'get')
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Get Customer Suport list BY FILTER 
export const GetTicketDetails = async (id) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.ticket
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}




// Get Customer Suport list BY FILTER 
export const AssignTicket = async (payload) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/assign`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Save Ticket
export const SaveTicket = async (payload) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/save`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Sales management

// Get Customer Suport list 
export const GetSalesSupport = async () => {
    try {
        let response = await callPrivateApi('admin/support-ticket/list/buyer_enquiry?page=1&per_page=10', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Customer Suport list BY FILTER 
export const GetSalesSearch = async (page_no, search) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/list/buyer_enquiry?search=${search}&page=${page_no}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Customer Suport list BY FILTER 
export const GetChatSearch = async (page_no, search) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/list/support_chat?search=${search}&page=${page_no}&per_page=10`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.tickets
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Customer Suport list BY FILTER 
export const GetSalesDetails = async (id) => {
    try {
        let response = await callPrivateApi(`admin/vehicle/sale/vehicle-details/${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.ticket
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Customer Suport list BY FILTER 
export const GetUserDetails = async (id) => {
    try {
        let response = await callPrivateApi(`admin/user-details/${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.user
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Get Customer Suport list BY FILTER 
export const GetUserTicketDetail = async (id) => {
    try {
        let response = await callPrivateApi(`admin/support-ticket/chat/with-user/${id}`, 'get')
        if (response.success === true) {
            let res = Object.assign({}, ...response?.data)
            return res?.tickets
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}