import React, { useState } from "react";
// import rent from '../../../assests/rent.png'
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import EyeIcon from "../../../../assests/EyeIcon";
import TrashIcon from "../../../../assests/TrashIcon";
import EditIcon from "../../../../assests/EditIcon";
import {  useNavigate } from "react-router-dom";
import LoaderSpinner from "../../../../util/LoaderSpinner";
import { GetChatSupportByPage } from "../../../../lib/Api/CustomerSupport";
import moment from "moment";
import TruncateText from "../../../../util/TruncateShow";
const RegisterChatUserDetail = ({ loading, supports, totalPage, setLoading, setSupports }) => {
  const navigate = useNavigate();
  const [itemperPage, setItemPerPage] = useState(10);

  const pageCount = Math.ceil(totalPage / itemperPage);

  const handlePageClick = async (event) => {
    setLoading(true);
    let { res } = await GetChatSupportByPage(event.selected + 1);
    setLoading(false);
    setSupports(res?.data)
  };


  const handleNavigate = (item) => {
    navigate(`/dinss/tech/chatUser-detail/${item.user_id}`)
}

return (
  <>
    <div>
      <div className="overflow-x-auto bg-white rounded-md mt-6 shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)]">
        {
          supports.length > 0 ?
            <>
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs font-[500] bg-[#FFF8EF] border-0">
                  <tr className="text-[#626973]">
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap w-px">
                      <div className="font-[500] text-[14px] text-left">ID</div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Date / Time
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Username
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Email
                      </div>
                    </th>

                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Title
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Last Message
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-left">
                        Status
                      </div>
                    </th>
                    <th className="px-5 py-4 pr-[25px] whitespace-nowrap">
                      <div className="font-[500] text-[14px] text-center">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm">
                  {
                    !loading ?
                      supports.map((item, i) => (
                        <tr className=" cursor-pointer" key={i}>
                          <td className="px-5 py-4 pr-[25px] flex items-center whitespace-nowrap">
                            <div className="text-left text-[14px] text-[#898989]">
                              {i + 1}
                            </div>
                          </td>
                          <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                            <div className="text-left text-[14px] text-[#898989]">
                              {moment(item?.created_at).format('ll HH:mm')}
                            </div>
                          </td>
                          <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                            <div className="text-left text-[14px] text-[#898989]">
                              {item?.user?.first_name} {item?.user?.last_name}
                            </div>
                          </td>
                          <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                            <div className="text-left text-[14px] text-[#898989]">
                              {item?.user?.email}
                            </div>
                          </td>

                          <td className="px-5 py-4 pr-[25px] ">
                            <div className="text-left text-[14px] text-[#898989]">
                            <TruncateText text={item?.subject} maxLength={40} />
                            </div>
                          </td>
                          <td className="px-5 py-4 pr-[25px]">
                            <div className="text-left text-[14px] text-[#898989]">
                            <TruncateText text={item?.last_message_text} maxLength={40} />
                              {`${moment(item?.last_message_time).format('HH:mm')}`} ({item?.unread_counts})
                            </div>
                          </td>

                          <td className="px-5 py-4 pr-[25px] whitespace-nowrap">

                            <div className={`text-center py-[3px] w-fit px-2.5 text-[12px] leading-4 ${item.status === 0 ? 'text-[#0041B2]' : 'text-green-500'} rounded-full ${item.status === 0 ? 'bg-[#E9F3FF]' : 'bg-green-200'} `}>
                              {item?.status === 0 ? 'Open' : "Close"}
                            </div>
                          </td>
                          <td className="px-5 py-4 pr-[25px] whitespace-nowrap">
                            <div className="text-center flex justify-center ">
                              {/* <BsThreeDotsVertical className='text-[20px] text-[#898989]' /> */}
                              {/* <GenericDrodown /> */}

                              <div class="group inline-block">
                                <button class="outline-none focus:outline-none  px-3 py-1 bg-white rounded-sm flex items-center">
                                  <BsThreeDotsVertical className="text-[20px] text-[#898989]" />
                                </button>
                                <ul
                                  class="bg-white shadow-[0_4px_20px_0px_rgba(0,0,0,0.08)] p-2 rounded-md transform scale-0 group-hover:scale-100 absolute 
  transition duration-150 ease-in-out right-7 origin-top lg:w-[12%]  "
                                >
                                  <li class="rounded-sm flex items-center px-2 py-2 mb-2 hover:bg-gray-100">
                                    <EditIcon className="text-[15px]" />
                                    <h2 className="ml-2">Edit</h2>
                                  </li>
                                  <li class="rounded-sm flex items-center px-2 py-2 mb-2 hover:bg-gray-100">
                                    <TrashIcon />
                                    <h2 className="ml-2">Delete</h2>
                                  </li>
                                  <li
                                    onClick={() => handleNavigate(item)}
                                    class="rounded-sm actions flex items-center px-2 py-2 hover:bg-gray-100"
                                  >
                                    <EyeIcon className="" />
                                    <h2 className="ml-2">View details</h2>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      : (
                        <div className="pt-4 flex items-center px-3 py-3">
                          <LoaderSpinner type="tableLoad" />
                        </div>
                      )
                  }
                </tbody>
              </table>
            </>
            : (
              <div className="p-5 ">
                {loading ? (
                  <LoaderSpinner type="tableLoad" />
                ) : (
                  <h2>Record not found</h2>
                )}
              </div>
            )
        }
      </div>
      <div className="flex flex-wrap justify-end items-center pt-6">

        <div className="lg:px-8 my-6 ">
          <ReactPaginate
            breakLabel="..."
            breakClassName=" bg-[#F3F3F3]"
            nextLabel={<MdOutlineKeyboardArrowRight />}
            onPageChange={handlePageClick}
            activeClassName="bg-[#FF8C00] text-white rounded"
            nextClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
            previousClassName="px-3  bg-[#F3F3F3] text-[20px] pt-[5px] rounded"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={<MdOutlineKeyboardArrowLeft />}
            className=" flex justify-end"
            pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] "
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  </>
);
};

export default RegisterChatUserDetail;