import Dashboard from "../pages/admindashboard/Dashboard";
import AuthProvider from "../hooks/AuthProvider";
import Login from "../pages/authpages/Login";
import SignUp from "../pages/authpages/SignUp";
import "./App.css";
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	createRoutesFromElements,
} from "react-router-dom";
import UserManagement from "../pages/admindashboard/UserManagement";
import Landing from "../pages/landingpages/Landing";
import Search from "../pages/landingpages/Search";
// import Detail from "../pages/landingpages/Detail";
import Rent from "../pages/landingpages/Rent";
import Helpsupport from "../pages/landingpages/Helpsupport";
import ContactUs from "../pages/landingpages/ContactUs";

import Blog from "../pages/landingpages/Blog";

import Article1 from "../pages/blogs/Article1";
import Article2 from "../pages/blogs/Article2";
import Article3 from "../pages/blogs/Article3";
import Article4 from "../pages/blogs/Article4";

import Professional from "../pages/landingpages/Professional";
import Gcu from "../pages/landingpages/Gcu";
// import GcuDetail from "../pages/landingpages/GcuDetail";
import HelpSupportDetail from "../components/MainLandingComp/helpSupport/HelpSupportDetail";
import Tickets from "../pages/admindashboard/Tickets";
import Reports from "../pages/admindashboard/Reports";
import Marketing from "../pages/admindashboard/Marketing";
import CustomerReports from "../pages/admindashboard/CustomerReports";
import GlobelReports from "../pages/admindashboard/GlobelReports";
import BroadCast from "../pages/admindashboard/BroadCast";
import ManageRoles from "../pages/admindashboard/ManageRoles";
// import Management from '../components/MainLandingComp/rent/management';
import Managements from "../pages/admindashboard/Management";
import Profile from "../pages/admindashboard/Profile";
import Forgot from "../pages/authpages/Forgot";
import RentalIndex from "../components/AdminComponents/manageUser/vihicalRenterSteps/RentalIndex";
import RentalIndex2 from "../components/AdminComponents/manageSales/vihicalRenterSteps/RentalIndex";
import TicketDetail from "../components/AdminComponents/CustomerSupports/DetailTicket/TicketDetail";
import ReportDetail from "../components/AdminComponents/CustomerSupports/DetailReport";
import CarlistDetail from "../components/AdminComponents/management/vihicalRenterSteps/carlistDetail/CarlistDetail";
import BookingDetail from "../components/AdminComponents/management/vihicalRenterSteps/bookingTables/BookingDetail";
import PaymentDetail from "../components/AdminComponents/management/vihicalRenterSteps/PaymentTables/PaymentDetail";
import MetaAds from "../pages/landingpages/MetaAds";
// import RenterDetail from "../components/AdminComponents/manageUser/vihicalRenterSteps/RenterDetail";
// import { newData } from "../components/MainLandingComp/helpSupport/data";
import Privacy from "../pages/landingpages/Privacy";
import ErrorPage from "../pages/error/error";
import Afiliate from "../pages/admindashboard/Afiliate";
import Download from "../pages/landingpages/Download";
import ServiceFee from "../pages/admindashboard/ServiceFee";
import VehicleDetails from "../pages/landingpages/VehicleDetails";
import Orders from "../pages/admindashboard/Orders";
import SendCode from "../pages/admindashboard/SendCode";
import KpiMatrics from "../pages/admindashboard/KpiMatrics";
import RegisterUsers from "../pages/admindashboard/RegisterUsers";
import PendingTransfer from "../pages/admindashboard/PendingTransfer";
import SalesManagement from '../pages/admindashboard/SalesManagement'
import RegisterUserDetail from "../components/AdminComponents/manageUser/registerUsers/RegisterDetail";
import RegisterChatUsers from "../components/AdminComponents/manageUser/registerUsers/RegisterChatUsers";
// import SendNotification from "../pages/admindashboard/SendNotification";
// 

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path="*" element={<ErrorPage />} />
			<Route path="/">
				<Route index element={<Landing />} />
				<Route path="/dinss/tech/signin" element={<Login />} />
				<Route path="/dinss/tech/signup" element={<SignUp />} />
				<Route path="/dinss/tech/forgot" element={<Forgot />} />
				<Route path="/search" element={<Search />} />
				{/* <Route path="/detail" element={<Detail />} /> */}
				<Route
					path="/deplacement-professionnel"
					element={<Professional />}
				/>
				<Route path="/louer-mon-vehicule" element={<Rent />} />
				<Route path="/help-center" element={<Helpsupport />} />
				<Route path="/contact" element={<ContactUs />} />
				<Route path="/gcu" element={<Gcu />} />
				<Route path="/privacy" element={<Privacy />} />
				{/* <Route path="/gcu_detail" element={<GcuDetail />} /> */}
				{/* <Route path="/helpsupport_detail/:itemId" element={<HelpSupportDetail />} /> */}
				{/* <Route path="/helpsupport_detail/:questionId" element={<HelpSupportDetail data={newData} />} /> */}
				{/* <Route
					path="/helpsupport_detail/:question"
					element={<HelpSupportDetail />}
				/> */}
				<Route
					path="/ads/cta/locataires"
					element={<MetaAds />}
				/>
				<Route
					path="/app/download"
					element={<Download />}
				/>


				<Route
					path="/help-center/locataire/trouver-un-vehicule/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/locataire/recuperez-le/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/locataire/reservez/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/locataire/Apres-la-location/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/proprietaire/Depot/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/proprietaire/Faites-plus-du-revenu/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/proprietaire/Acceptez-les-demandes-de-location/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/help-center/locataire/Autre-Articles/:question"
					element={<HelpSupportDetail />}
				/>

				<Route path="/blog" element={<Blog />} />

				<Route
					path="/blog/trouvez-la-meilleure-location-de-voiture-pour-vos-vacances-en-cote-divoire"
					element={<Article1 />}
				/>
				<Route
					path="/blog/louer-une-voiture-pour-votre-entreprise-ou-en-acheter-en-cote-divoire"
					element={<Article2 />}
				/>
				<Route
					path="/blog/ce-quil-faut-savoir-sur-la-location-de-vehicule-utilitaire"
					element={<Article3 />}
				/>
				<Route
					path="/blog/nos-conseils-pour-faire-des-annonces-avec-de-belles-photos-sur-primecar"
					element={<Article4 />}
				/>

				<Route path="/gcu" element={<Gcu />} />
				<Route path="/privacy" element={<Privacy />} />
				{/* <Route path="/gcu_detail" element={<GcuDetail />} /> */}
				{/* <Route path="/helpsupport_detail/:itemId" element={<HelpSupportDetail />} /> */}
				{/* <Route path="/helpsupport_detail/:questionId" element={<HelpSupportDetail data={newData} />} /> */}
				<Route
					path="/helpsupport_detail/:question"
					element={<HelpSupportDetail />}
				/>
				<Route
					path="/vehicles/:vehicleId"
					element={<VehicleDetails />}
				/>

			</Route>

			{/* Dashboard */}
			<Route element={<AuthProvider />}>
				<Route path="/dinss/tech/dashboard" element={<Dashboard />} />
				<Route
					path="/dinss/tech/dashboard/vehical-detail/:id/:name"
					element={<RentalIndex name="Dashboard" />}
				/>
				{/* user management */}
				<Route
					path="/dinss/tech/vehicalRenters"
					element={<UserManagement />}
				/>
				<Route
					path="/dinss/tech/vehicalRenters/detail/:id/:name"
					element={<RentalIndex />}
				/>
				<Route
					path="/dinss/tech/vehicalOwner"
					element={<UserManagement />}
				/>
				<Route
					path="/dinss/tech/vehicalOwner/detail/:id/:name"
					element={<RentalIndex />}
				/>

				<Route
					path="/dinss/tech/registerUsers"
					element={<RegisterUsers />}
				/>
				<Route path="/dinss/tech/chatWithUsers" element={<RegisterChatUsers />} />
				<Route
					path="/dinss/tech/chatUser-detail/:id"
					element={<RegisterUserDetail />}
				/>
				<Route
					path="/dinss/tech/registerUser-detail/:id"
					element={<RegisterUserDetail />}
				/>


				{/* Customer Management */}
				<Route
					path="/dinss/tech/customerRenter"
					element={<CustomerReports />}
				/>
				<Route
					path="/dinss/tech/customerRenter/detail/:id/:name"
					element={<RentalIndex />}
				/>
				<Route
					path="/dinss/tech/customerOwner"
					element={<CustomerReports />}
				/>
				<Route
					path="/dinss/tech/customerOwner/detail/:id/:name"
					element={<RentalIndex />}
				/>
				{/* Seller Management */}
				<Route
					path="/dinss/tech/vehicalSaller"
					element={<SalesManagement />}
				/>
				<Route
					path="/dinss/tech/vehicalSaller/detail/:id/:name"
					element={<RentalIndex2 />}
				/>

				<Route path="/dinss/tech/vehicalBuyer" element={<Tickets />} />
				<Route
					path="/dinss/tech/vehicalBuyer/:id"
					element={<TicketDetail />}
				/>



				<Route
					path="/dinss/tech/globelRenter"
					element={<GlobelReports />}
				/>
				<Route
					path="/dinss/tech/globelOwner"
					element={<GlobelReports />}
				/>
				<Route
					path="/dinss/tech/kpiMatric"
					element={<KpiMatrics />}
				/>
				<Route path="/dinss/tech/broadcast" element={<BroadCast />} />
				<Route path="/dinss/tech/orders" element={<Orders />} />
				<Route path="/dinss/tech/sendCode" element={<SendCode />} />
				<Route
					path="/dinss/tech/manageRole"
					element={<ManageRoles />}
				/>
				<Route
					path="/dinss/tech/transfer"
					element={<PendingTransfer />}
				/>
				<Route
					path="/dinss/tech/management"
					element={<Managements />}
				/>

				<Route
					path="/dinss/tech/management/booking-detail/:id"
					element={<BookingDetail />}
				/>
				<Route
					path="/dinss/tech/management/payment-detail/:id"
					element={<PaymentDetail />}
				/>
				<Route
					path="/dinss/tech/management/detail/:id"
					element={<CarlistDetail />}
				/>
				<Route
					path="/dinss/tech/afiliate"
					element={<Afiliate />}
				/>

				<Route
					path="/dinss/tech/servicefee"
					element={<ServiceFee />}
				/>

				<Route path="/dinss/tech/promocode" element={<Marketing />} />

				<Route path="/dinss/tech/ticket" element={<Tickets />} />
				<Route
					path="/dinss/tech/ticket-detail/:id"
					element={<TicketDetail />}
				/>
				<Route path="/dinss/tech/report" element={<Reports />} />
				<Route
					path="/dinss/tech/report-detail/:id"
					element={<ReportDetail />}
				/>
				<Route path="/dinss/tech/profile" element={<Profile />} />
			</Route>
		</>
	)
);

function App() {
	return <RouterProvider router={router} />;
}

export default App;